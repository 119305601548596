<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <router-view></router-view>
       <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
export default {
  components: {
    NavMain,
    FooterAll
  },
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>
<style lang="less" scoped>
.el-container {
    flex-direction: column;
  height: 100%;
  .el-container {
    // height: e("calc(100% - 70px)");
  }
  .el-main {
    background-color: #f8f8f8;
    padding:0;
  }
}

</style>